// Generated by Framer (e1022ef)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["RwnqCVRiq"];

const serializationHash = "framer-7d08h"

const variantClassNames = {RwnqCVRiq: "framer-v-16mhzvy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, pillColor, text, textColor, width, ...props}) => { return {...props, Vg1ZPuQyX: pillColor ?? props.Vg1ZPuQyX ?? "rgb(62, 58, 242)", VvNtbAvMC: textColor ?? props.VvNtbAvMC ?? "var(--token-0f4ab4bd-730d-4a3e-bd70-049bfead3b9e, rgb(62, 58, 242))", Y52ffg9mU: text ?? props.Y52ffg9mU ?? "TAG"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;textColor?: string;pillColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Y52ffg9mU, VvNtbAvMC, Vg1ZPuQyX, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "RwnqCVRiq", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-16mhzvy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"RwnqCVRiq"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(238, 242, 255)", borderBottomLeftRadius: 38, borderBottomRightRadius: 38, borderTopLeftRadius: 38, borderTopRightRadius: 38, ...style}}><motion.div className={"framer-nt5xvg"} data-framer-name={"Dot"} layoutDependency={layoutDependency} layoutId={"iaqnULj5H"} style={{backgroundColor: Vg1ZPuQyX, borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SUJNIFBsZXggTW9uby02MDA=", "--framer-font-family": "\"IBM Plex Mono\", monospace", "--framer-font-size": "12px", "--framer-font-weight": "600", "--framer-letter-spacing": "0.06em", "--framer-line-height": "20px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-VvNtbAvMC-qRBLojNMO))"}}>TAG</motion.p></React.Fragment>} className={"framer-1n84kkc"} data-framer-name={"Text"} fonts={["GF;IBM Plex Mono-600"]} layoutDependency={layoutDependency} layoutId={"HSt5lmclc"} style={{"--extracted-r6o4lv": "var(--variable-reference-VvNtbAvMC-qRBLojNMO)", "--framer-paragraph-spacing": "14px", "--variable-reference-VvNtbAvMC-qRBLojNMO": VvNtbAvMC}} text={Y52ffg9mU} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-7d08h[data-border=\"true\"]::after, .framer-7d08h [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7d08h.framer-11n4r5, .framer-7d08h .framer-11n4r5 { display: block; }", ".framer-7d08h.framer-16mhzvy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 2px 10px 2px 8px; position: relative; width: min-content; }", ".framer-7d08h .framer-nt5xvg { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 6px); position: relative; width: 6px; }", ".framer-7d08h .framer-1n84kkc { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7d08h.framer-16mhzvy { gap: 0px; } .framer-7d08h.framer-16mhzvy > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-7d08h.framer-16mhzvy > :first-child { margin-left: 0px; } .framer-7d08h.framer-16mhzvy > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 54
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Y52ffg9mU":"text","VvNtbAvMC":"textColor","Vg1ZPuQyX":"pillColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerqRBLojNMO: React.ComponentType<Props> = withCSS(Component, css, "framer-7d08h") as typeof Component;
export default FramerqRBLojNMO;

FramerqRBLojNMO.displayName = "Tag";

FramerqRBLojNMO.defaultProps = {height: 24, width: 54};

addPropertyControls(FramerqRBLojNMO, {Y52ffg9mU: {defaultValue: "TAG", displayTextArea: false, title: "Text", type: ControlType.String}, VvNtbAvMC: {defaultValue: "var(--token-0f4ab4bd-730d-4a3e-bd70-049bfead3b9e, rgb(62, 58, 242))", title: "Text Color", type: ControlType.Color}, Vg1ZPuQyX: {defaultValue: "rgb(62, 58, 242)", title: "Pill Color", type: ControlType.Color}} as any)

addFonts(FramerqRBLojNMO, [{family: "IBM Plex Mono", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3vAO8lJPg-IUDNg.woff2", weight: "600"}])